import(/* webpackMode: "eager", webpackExports: ["NotificationProvider"] */ "/app/apps/editor/app/app/[locale]/shared/Notification.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/apps/editor/app/app/[locale]/shared/registry.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["QueryClientProvider"] */ "/app/apps/editor/app/app/shared/QueryClientProvider.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["SystemProvider"] */ "/app/apps/editor/app/app/shared/SystemProvider.tsx");
;
import(/* webpackMode: "eager" */ "/app/node_modules/@xyflow/react/dist/style.css");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/node_modules/next-intl/dist/esm/shared/NextIntlClientProvider.js");
;
import(/* webpackMode: "eager" */ "/app/node_modules/next/dist/client/script.js");
;
import(/* webpackMode: "eager" */ "/app/node_modules/next/font/google/target.css?{\"path\":\"app/[locale]/layout.tsx\",\"import\":\"Work_Sans\",\"arguments\":[{\"subsets\":[\"latin\"],\"variable\":\"--fontFamily-sans\",\"display\":\"swap\"}],\"variableName\":\"workSans\"}");
;
import(/* webpackMode: "eager" */ "/app/node_modules/next/font/google/target.css?{\"path\":\"app/[locale]/layout.tsx\",\"import\":\"Work_Sans\",\"arguments\":[{\"subsets\":[\"latin\"],\"variable\":\"--font-sans\",\"display\":\"swap\"}],\"variableName\":\"workSansForShadCn\"}");
;
import(/* webpackMode: "eager" */ "/app/node_modules/next/font/google/target.css?{\"path\":\"app/[locale]/layout.tsx\",\"import\":\"Roboto_Slab\",\"arguments\":[{\"subsets\":[\"latin\"],\"variable\":\"--fontFamily-serif\",\"display\":\"swap\"}],\"variableName\":\"robotoSlab\"}");
;
import(/* webpackMode: "eager", webpackExports: ["__esModule","default"] */ "/app/node_modules/nextjs-toploader/dist/index.js");
;
import(/* webpackMode: "eager" */ "/app/packages/design-system/src/global.css");
;
import(/* webpackMode: "eager" */ "/app/packages/design-system/src/themes/app.css");
;
import(/* webpackMode: "eager" */ "/app/packages/design-system/src/Tooltip/Tooltip.tsx");
